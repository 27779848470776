import React from 'react';
import { DetailBox, DetailsContainer, HighlightBox } from './styled';

const Details = () => {
  return (
    <DetailsContainer>
      <DetailBox>
        <HighlightBox>
          <h2>Contact Details:</h2>
        </HighlightBox>
        <p>
          <strong>Address: </strong>
          Hungary, 1025 Budapest Szépvölgyi street 52
        </p>
        <p>
          <strong>Phone Number: </strong>
          +36-30-941-22-25
        </p>
        <p>
          <strong>Email: </strong>
          info@fattomonki.com
        </p>
        <p>
          <strong>facebook: </strong>
          Fattomonki
        </p>
        <p>
          <strong>Instagram: </strong>
          monki.cycles
        </p>
        <HighlightBox>
          <h2>Opening Hours:</h2>
        </HighlightBox>
        <p>
          <strong>Monday - Friday: </strong>
          9:00 AM - 5:00 PM
        </p>
        <p>

          <strong>Saturday: </strong>
          10:00 AM - 2:00 PM
        </p>
        <p>
          <strong>Sunday: </strong>
          Closed
        </p>
      </DetailBox>
    </DetailsContainer>
  );
};

export default Details;
