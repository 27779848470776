import { styled } from 'styled-components';

export const Container = styled.div`
width: 100wh;
height: 100vh;


@media only screen and (max-width: 600px) {
       height: 100svh;
    }
`;

export const BackContainer = styled.div`
background-color: #ffc31a;
transform: skew(-15deg);
padding: 8px 15px;
border: 2px solid #000;
cursor: pointer;

a{
    font-weight: 700;
    color: #000;
    text-decoration: none;
}

&:hover{
    background-color: #000;
    color: #F8F8F8;

    a{ 
        color: #F8F8F8;
    }
}

@media only screen and (max-width: 600px) {
        padding: 6px 12px;

        a{
            font-size: 0.8rem;
        }
    }
`;

export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
`;

export const HeaderBox = styled.div`
height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px;

  h1{
    color: #F8F8F8;
    text-shadow: 1px 3px 3px #000;
    font-size: 5rem;
    font-family: 'Shadows Into Light Two', cursive;

    @media only screen and (max-width: 600px) {
        font-size: 3rem;
    }
  }


`;

export const Logo = styled.img`
  width: 120px;

  @media only screen and (max-width: 600px) {
        width: 70px;
    }
`;

export const Loading = styled.h1`
width: 100vw;
margin-right: 50px;
`;
