import React from 'react';
import Carousel from 'react-gallery-carousel';
import { aboutText } from '../../constants';
import { AboutContainer, AboutContent, AboutTextContainer, AboutTitle } from './styled';
import model1 from '../../assets/modelImages/picture1.jpg';
import model2 from '../../assets/modelImages/picture2.jpg';
import model3 from '../../assets/modelImages/picture3.jpg';
import model4 from '../../assets/modelImages/picture4.jpg';

const About = () => {
  const carouselImagesArray = [model1, model2, model3, model4];
  const images = carouselImagesArray.map((image) => ({
    src: image,
  }));
  return (
    <AboutContainer id="about">
      <AboutTextContainer>
        <AboutTitle>About the brand</AboutTitle>
        <AboutContent>{aboutText}</AboutContent>
        <Carousel
          images={images}
          isAutoPlaying
          hasSizeButton={false}
          hasMediaButton={false}
          hasIndexBoard={false}
          objectFit="contain"
          style={{ height: 500, paddingBottom: 50 }}
        />
      </AboutTextContainer>
    </AboutContainer>
  );
};

export default About;
