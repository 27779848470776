import { styled } from 'styled-components';

export const HighlightBox = styled.div`
background-color: #ffc31a;
transform: skew(-15deg);
margin: 10px 0 40px 0;

  h2{
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 6px black;
  }
`;

export const DetailsContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
gap:10px;

@media only screen and (max-width: 600px) {
    justify-content: flex-start;
    }
`;

export const DetailBox = styled.div`
padding: 20px;
background: #000;
color: #fff;
border-radius: 5px;

@media only screen and (max-width: 600px) {
   font-size: 0.8rem;
    }

strong{
    color: #ffc31a;
    margin-right: 5px;
}
`;
