import { styled } from 'styled-components';

export const TechnologyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 3rem;

    @media only screen and (max-width: 600px) {
      font-size: 2rem;
    }
  }
  h2 {
    position: relative;
    margin: 0;
    font-size: 1.8rem;
    font-family: 'Shadows Into Light Two', cursive;
    font-style: italic;

    @media only screen and (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  h2:before {
    background-color: #ffc31a;
    content: '';
    position: absolute;
    width: calc(100% + 10px);
    height: 70%;
    left: -2px;
    bottom: 0;
    z-index: -1;
    transform: rotate(-2deg);
  }
`;

export const Content = styled.div`
  margin-top: 40px;
  width: 80%;
`;

export const ContentTitle = styled.div``;

export const ContentText = styled.div``;
