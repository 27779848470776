import React from 'react';
import { Content, ContentText, ContentTitle, TechnologyContainer, Title } from './styled';

const Technology = () => {
  return (
    <TechnologyContainer id="technology">
      <Title>
        <h1>Fattomonki Sisu Bike</h1>
        <h2>Where Precision Meets Passion</h2>
      </Title>
      <Content>
        <ContentTitle>Frame</ContentTitle>
        <ContentText>
          Our innovative carbon composite frame stands at the forefront of ergonomics and durability. Offering
          outstanding strength and rigidity, it maintains minimal weight, ensuring a difference you can feel with every
          pedal.
        </ContentText>
      </Content>

      <Content>
        <ContentTitle>Suspension Travel:</ContentTitle>
        <ContentText>
          With a 160mm travel, the Sisu is designed to perform on the toughest terrains. Whether it&#39;s technical
          descents or mountain trails, with Sisu, you&#39;re always leading the pack.
        </ContentText>
      </Content>

      <Content>
        <ContentTitle>Kinematics</ContentTitle>
        <ContentText>
          Thanks to our exceptional anti-squat values, energy efficiency has never been so high. Less undesired rear
          shock movement and improved braking efficiency on technical slopes? With Sisu, both are possible.
        </ContentText>
      </Content>

      <Content>
        <ContentTitle>Technology</ContentTitle>
        <ContentText>
          Designed and optimized in every detail using ANSYS kinematic simulation software. You&#39;re not just getting
          a bike, but an engineering marvel that will stay with you for years.
        </ContentText>
      </Content>

      <Content>
        <ContentTitle>License</ContentTitle>
        <ContentText>
          Our SRAM Universal Derailleur Hanger license ensures that the Sisu is compatible with market-leading
          components. This way, you can easily upgrade or replace parts anytime.
        </ContentText>
      </Content>

      <Content>
        <ContentTitle>Personal Touch</ContentTitle>
        <ContentText>
          Each of our Sisu bikes is crafted under the &quot;One Man, One Frame&quot; production strategy. This means
          every single piece of the bike frame is made under the hands of an expert, complete with their signature.
          Because we believe passion and dedication make the difference.
        </ContentText>
      </Content>

      <Content>
        <ContentTitle>Eco-Consciousness:</ContentTitle>
        <ContentText>
          Fattomonki is committed to sustainability. During the Sisu bike production process, we use materials and
          technologies that minimize environmental footprint while meeting the highest quality expectations.
        </ContentText>
      </Content>
    </TechnologyContainer>
  );
};

export default Technology;
