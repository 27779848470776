import React from 'react';
import { placeholderText } from '../../constants';
import { PlaceHolderContainer, PlaceHolderText } from './styled';

const Quote = () => {
  return (
    <PlaceHolderContainer>
      <PlaceHolderText>{placeholderText}</PlaceHolderText>
    </PlaceHolderContainer>
  );
};

export default Quote;
