import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { DesktopNav, HeaderContainer, Link, Logo, MobileNav, Philosophy, PhilosophyContainer } from './styled';
import logo from '../../assets/fattomonkiLogo.png';
import { ReactComponent as CrossIcon } from '../../assets/close.svg';

const randNum = Math.floor(Math.random() * 2);
const headUnderlineText = [
  'Riding is everything',
  'We can only give you the opportunity to be dirty and tired, but happy at the same time',
];
const randomizedheadUnderlineText = headUnderlineText[randNum];
export const Header = () => {
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };
  return (
    <HeaderContainer>
      <MobileNav>
        <Logo src={logo} alt="logo" />
        <Menu
          isOpen={isOpen}
          onOpen={handleIsOpen}
          onClose={handleIsOpen}
          customCrossIcon={<CrossIcon />}
          width="50%"
          left
        >
          <Link onClick={closeSideBar} href="#about">
            About
          </Link>
          <Link onClick={closeSideBar} href="#technology">
            Technology
          </Link>
          <Link onClick={closeSideBar} href="/model-frame">
            3D Model
          </Link>
          <Link onClick={closeSideBar} href="#contact">
            Contact
          </Link>
        </Menu>
      </MobileNav>

      <DesktopNav>
        <Link href="#about">About</Link>
        <Link href="#technology">Technology</Link>
        <Logo src={logo} alt="logo" />
        <Link href="/model-frame">3D Model</Link>
        <Link href="#contact">Contact</Link>
      </DesktopNav>

      <PhilosophyContainer>
        <Philosophy num={randNum}>{randomizedheadUnderlineText}</Philosophy>
      </PhilosophyContainer>
    </HeaderContainer>
  );
};
