import React from 'react';
import {
  ContactsContainer,
  ContentContainer,
  CopyRight,
  CopyRightContainer,
  FooterContainer,
  FooterTitle,
  FormContainer,
  InformationBlock,
  Link,
  Logo,
  LogoContainer,
  NavContainer,
  SocialContainer,
  SocialIcon,
  StyledButton,
  StyledForm,
  TextAreaInput,
} from './styled';
import logo from '../../assets/fattomonkiLogo.png';
import fbIcon from '../../assets/fb.png';
import instaIcon from '../../assets/insta.png';

const Form = () => {
  return (
    <StyledForm name="mailtoform" action="mailto:info@fattomonki.com" method="post" encType="multipart/form-data">
      <TextAreaInput name="body" rows="5" cols="30" placeholder="Your Message" />
      <br />
      <StyledButton type="submit">Send</StyledButton>
    </StyledForm>
  );
};

export const Footer = () => {
  return (
    <FooterContainer>
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>

      <ContentContainer>
        <ContactsContainer>
          <FooterTitle>Contact</FooterTitle>
          <InformationBlock>
            <strong>Address: </strong>
            <br />
            Hungary, 1025 Budapest Szépvölgyi street 52
          </InformationBlock>
          <InformationBlock>
            <strong>Email: </strong>
            <br />
            info@fattomonki.com
          </InformationBlock>
          <InformationBlock>
            <strong>Phone: </strong>
            <br />
            +36-30-941-22-25
          </InformationBlock>
        </ContactsContainer>

        <NavContainer>
          <FooterTitle>Menu</FooterTitle>
          <Link href="#about">About</Link>
          <Link href="#technology">Technology</Link>
          <Link href="model-frame">Demo</Link>
          <Link href="#contact">Contact</Link>
        </NavContainer>

        <FormContainer>
          <FooterTitle>Text us</FooterTitle>
          <Form />
        </FormContainer>
      </ContentContainer>

      <SocialContainer>
        <a href="https://www.instagram.com/monki.cycles/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
          <SocialIcon src={instaIcon} />
        </a>
        <a href="https://www.facebook.com/Fattomonki">
          <SocialIcon src={fbIcon} />
        </a>
      </SocialContainer>

      <CopyRightContainer>
        <CopyRight>© Copyright 2023 | Fattomonki, Hungary, 1025 Budapest Szépvölgyi street 52</CopyRight>
      </CopyRightContainer>
    </FooterContainer>
  );
};
