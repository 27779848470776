import { styled } from 'styled-components';

export const AboutContainer = styled.div`
  padding-top: 70px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const AboutTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const AboutTitle = styled.h1`
  color: #f8f8f8;
  font-size: 3rem;

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
`;

export const AboutContent = styled.p`
  color: #f8f8f8;
  text-align: justify;
  line-height: 30px;
  margin-bottom: 50px;

  @media only screen and (max-width: 600px) {
    width: 80%;
    margin-bottom: 0;
  }
`;
