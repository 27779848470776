import ReactDOM from 'react-dom';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import { ThreeDModel } from './components/Model';

export default function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<App />} />
        <Route path="/model-frame" element={<ThreeDModel />} />
      </Routes>
    </BrowserRouter>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<Root />, rootElement);
