import { styled } from 'styled-components';

export const PlaceHolderContainer = styled.div`
  background: #f8f8f8;
  padding: 50px 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlaceHolderText = styled.h1`
  color: #000;
  line-height: 40px;
  font-size: 1.9rem;
  text-align: center;
  font-family: 'Shadows Into Light Two', cursive;
  width: 70%;

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
`;
