import React, { useMemo } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import Details from '../Details';
import {
  Box,
  ContactContainer,
  ContactContentContainer,
  ContactText,
  ContactTitle,
  MainContentContainer,
  MapContainer,
} from './styled';
import { contactText } from '../../constants';

const Contact = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBw25L9r_AypN-W94UhjpsGBB7qBcn2eTw',
  });
  const center = useMemo(() => ({ lat: 47.528717041015625, lng: 19.03130340576172 }), []);
  return (
    <ContactContainer id="contact">
      <ContactContentContainer>
        <ContactTitle>Contact</ContactTitle>
        <ContactText>{contactText}</ContactText>
        <MainContentContainer>
          <Box>
            <Details />
          </Box>
          <MapContainer>
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <GoogleMap mapContainerClassName="map-container" center={center} zoom={17}>
                <Marker position={{ lat: 47.528717041015625, lng: 19.03130340576172 }} />
              </GoogleMap>
            )}
          </MapContainer>
        </MainContentContainer>
      </ContactContentContainer>
    </ContactContainer>
  );
};

export default Contact;
