import React from 'react';

import { MainContainer, VideoContainer } from './styled';
import videoBg from '../../assets/fattoMonkiVideoHigh.mp4';
import 'react-gallery-carousel/dist/index.css';
import Technology from '../Technology';
import Contact from '../Contact';
import About from '../About';
import Quote from '../Quote';

const VideoPlayer = () => {
  return <VideoContainer src={videoBg} type="video/mp4" autoPlay muted playsInline />;
};

export const Main = () => {
  return (
    <MainContainer>
      <VideoPlayer />
      <Quote />
      <About />
      <Technology />
      <Contact />
    </MainContainer>
  );
};
