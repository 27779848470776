import React from 'react';
import {
  Box, Container, ContentContainer, ProgressLineCOntainer,
} from './styled';
import { ReactComponent as Logo } from '../../assets/fattoMonkiHead.svg';
import { ProgressLine } from '../ProgressBar';

export const SplashScreen = () => {
  return (
    <Container>
      <ContentContainer>
        <Box>
          <Logo />
        </Box>
        <ProgressLineCOntainer>
          <ProgressLine
            backgroundColor="white"
            visualParts={[
              {
                percentage: '100%',
                color: '#F7B625',
              },
            ]}
          />
        </ProgressLineCOntainer>
      </ContentContainer>
    </Container>
  );
};
