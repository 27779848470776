import React, { Suspense } from 'react';
import { useLoader, Canvas } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Environment, OrbitControls } from '@react-three/drei';
import { Link } from 'react-router-dom';
import { Html } from '@react-three/drei/web/Html';
import {
  BackContainer, Container, HeaderBox, HeaderContainer, Loading, Logo,
} from './styled';
import logo from '../../assets/fattomonkiLogo.png';

const Model = () => {
  const gltf = useLoader(GLTFLoader, './fattoMonki.glb', (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/');
    loader.setDRACOLoader(dracoLoader);
  });
  return (
    <>
      <primitive object={gltf.scene} scale={2} position={[0.1, -0.5, 0]} children-0-castShadow />
    </>
  );
};

const BackButton = () => {
  return (
    <BackContainer>
      <Link to="/">HOME</Link>
    </BackContainer>
  );
};

const LoadinScreen = () => {
  return (
    <Html>
      <Loading>Model Initializing......</Loading>
    </Html>
  );
};

export const ThreeDModel = () => {
  return (
    <Container>
      <HeaderContainer>
        <HeaderBox>
          <BackButton />
          <h1>SISU</h1>
          <Logo src={logo} alt="logo" />
        </HeaderBox>
      </HeaderContainer>
      <Canvas camera={{ position: [-2.2, -0.3, 3] }} shadows>
        <Suspense fallback={<LoadinScreen />}>
          <directionalLight position={[3.3, 1.0, 4.4]} castShadow />
          <Model />
          <OrbitControls />
          <Environment preset="sunset" background />
        </Suspense>
      </Canvas>
    </Container>
  );
};
