import { styled } from 'styled-components';

export const Container = styled.div`
background-color:#EEEEEE;
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
`;

export const ContentContainer = styled.div`
display: flex;
width: 100vh;
flex-direction: column;
align-items: center;
`;

export const ProgressLineCOntainer = styled.div`
    width: 45%;

    @media only screen and (max-width: 600px) {
        width: 70%;
    }
`;

export const Box = styled.div`
margin: 0 0 2% 0;
width: 30%;

@media only screen and (max-width: 600px) {
    width: 60%;
    }
`;
