import { styled } from 'styled-components';

export const MainContainer = styled.div`
  padding-bottom: 50px;
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: 80vh;
  -o-object-fit: cover;
  object-fit: cover;

  @media only screen and (max-width: 600px) {
    height: 50vh;
  }
`;

export const TestVideoContainer = styled.div`
  width: 100%;
  object-fit: contain;
  display: flex;
`;

export const AboutLogoContainer = styled.div`
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
`;

export const AboutLogo = styled.img`
  width: 40%;
`;

export const CarouselContainer = styled.img`
  width: 40%;
`;

export const ContactDeatlContaner = styled.div``;

export const Logo = styled.img`
  width: 300px;
`;
