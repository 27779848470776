import { styled } from 'styled-components';

export const HeaderContainer = styled.div`
  background-color: #f8f8f8;
`;

export const Logo = styled.img`
  width: 200px;
  min-width: 120px;
  margin: 0.2rem 2rem;

  @media only screen and (max-width: 950px) {
    width: 150px;
  }

  @media only screen and (max-width: 600px) {
    width: 30%;
  }
`;

export const Link = styled.a`
  position: relative;
  line-height: 64px;
  font-size: 1.3rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  font-weight: bold;
  width: 16%;
  display: inline-block;
  transition: all s;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #aaa;
    height: 1px;
  }

  &:after {
    text-align: left;
    margin: 0;
    opacity: 0;
  }
  &:hover {
    z-index: 1;
  }
  &:hover:after {
    z-index: -10;
    animation: fill 0.5s forwards;
    -webkit-animation: fill 0.5s forwards;
    -moz-animation: fill 0.5s forwards;
    opacity: 1;
  }

  @-webkit-keyframes fill {
    0% {
      width: 0%;
      height: 1px;
    }
    50% {
      width: 100%;
      height: 1px;
    }
    100% {
      width: 100%;
      height: 100%;
      border-width: 3px 4px 3px 5px;
      border-radius: 95% 4% 92% 5%/4% 95% 6% 95%;
      border: 5px solid #000;
      transform: rotate(2deg) skew(-0.06turn);
      background: #ffc31a;
    }
  }

  @media only screen and (max-width: 950px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 600px) {
    color: #f8f8f8;
    width: 100%;
  }
`;

export const MobileNav = styled.div`
  display: none;

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const DesktopNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 0.5rem;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const PhilosophyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;

  margin-top: auto;
  background-color: #000;
`;

export const Philosophy = styled.p`
  text-align: center;
  font-family: 'Shadows Into Light Two', cursive;
  font-size: 1.1rem;
  font-style: italic;
  color: #f8f8f8;
  letter-spacing: 0.5px;

  @media only screen and (max-width: 600px) {
    font-size: ${({ num }) => (num === 0 ? '1.3rem' : '0.9rem')};
    margin: auto;
  }
`;
