import './styles.css';
import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { SplashScreen } from './components/SplashScreen';
import { Header } from './components/Header';
import { Main } from './components/Main';
import { Footer } from './components/Footer';
import { ReactComponent as Logo } from './assets/fattoMonkiHead.svg';

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 800);
  }, []);

  return (
    <>
      <ScrollToTop smooth component={<Logo />} />
      {loading && <SplashScreen />}
      {!loading && (
        <>
          <Header />
          <Main />
          <Footer />
        </>
      )}
    </>
  );
}
