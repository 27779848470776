import React, { useEffect, useState } from 'react';
import './ProgressLine.css';

export const ProgressLine = ({
  label,
  backgroundColor = '#e5e5e5',
  visualParts = [
    {
      percentage: '0%',
      color: 'white',
    },
  ],
}) => {
  const [widths, setWidths] = useState(
    visualParts.map(() => 0),
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(
        visualParts.map((item) => item.percentage),
      );
    });
  }, [visualParts]);

  return (
    <>
      <div className="progressLabel">{label}</div>
      <div
        className="progressVisualFull"
        style={{
          backgroundColor,
        }}
      >
        {visualParts.map((item, index) => (
          <div
            key={index}
            style={{
              width: widths[index],
              backgroundColor: item.color,
            }}
            className="progressVisualPart"
          />
        ))}
      </div>
    </>
  );
};

export default ProgressLine;
