import { styled } from 'styled-components';

export const FooterContainer = styled.div`
  height: 30%;
  background-color: #f8f8f8;
  border-top: 3px solid #000;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: #000;
  line-height: 30px;

  @media only screen and (max-width: 600px) {
    line-height: 20px;
  }

  &:hover {
    background-color: #000;
    color: #f8f8f8;
  }
`;

export const NavContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 600px) {
    width: 50%;
  }
`;

export const FormContainer = styled.div`
  min-width: 22%;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 100px;
  margin: 5px;
`;
export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5%;
  margin-top: auto;

  @media only screen and (max-width: 600px) {
    gap: 0%;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  gap: 10px;
`;

export const SocialIcon = styled.img`
  width: 40px;
  border-radius: 10px;
`;
export const CopyRightContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 3px solid #000;
  background: radial-gradient(circle, rgba(255, 227, 76, 1) 0%, rgba(255, 191, 19, 1) 93%);
  height: 50px;
`;

export const CopyRight = styled.p`
  font-size: 1rem;
`;
export const ContactsContainer = styled.div`
  width: 20%;
  text-align: center;

  @media only screen and (max-width: 600px) {
    width: 40%;
  }
`;

export const FooterTitle = styled.p`
  line-height: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 1.8rem;
  color: #000;
`;

export const InformationBlock = styled.p`
  font-size: 1rem;

  strong {
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;

    strong {
      font-size: 1rem;
    }
  }
`;

export const StyledForm = styled.form`
  background: #ffc31a;
  border: 3px solid #000;
  border-radius: 8px;
`;

export const TextAreaInput = styled.input`
  margin: 10px;
  width: -webkit-fill-available;
  height: 70px;
  resize: none;
  border-radius: 5px;
`;

export const StyledButton = styled.button`
  height: 30px;
  width: 90%;
  margin-bottom: 10px;
  margin-left: 10px;
  border-radius: 5px;
  width: 40%;
  &:hover {
    background-color: #000;
    color: #f8f8f8;
  }
`;
