import { styled } from 'styled-components';

export const ContactContainer = styled.div`
  padding: 40px 0;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ContactContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const ContactTitle = styled.h1`
  color: #f8f8f8;
  font-size: 3rem;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
`;

export const ContactText = styled.p`
  color: #f8f8f8;
  text-align: justify;
  line-height: 30px;
  margin-bottom: 60px;

  @media only screen and (max-width: 600px) {
    padding: 0;
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const Box = styled.div`
  flex: 470px;
`;

export const MapContainer = styled.div`
  flex: 470px;
  height: 400px;
`;
