export const placeholderText = '"Fattomonki is not just a brand, it\'s a community where facing challenges is the real success. Discover our latest highlight: the SISU, representing the values with which you can also identify."';
export const aboutText = `Established in 2022, Fattomonki is not just a new brand in the cycling world,
but an initiative brought to life by a passionately enthusiastic team.
Our goal isn't just to create a brand,
but to form a community for those who face challenges with faith.
Thanks to our collaboration with Ansys, we employ the most modern technologies,
combining them with traditional artisanal methods.
At the heart of our manufacturing philosophy is
the "one person - one frame" strategy,
ensuring that each frame is individually crafted with care
and bears the signature of its creator.
The SISU is the perfect embodiment of the values we work by.
Join us and experience the excitement of the Fattomonki world!"`;
export const contactText = `Thank you for your interest in Fattomonki.
If you have any questions or feedback about our products, services, or
the Fattomonki philosophy,
please get in touch with us. We are always ready to answer your inquiries
and provide you with
any information you might need.`;
